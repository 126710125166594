Model = function()
{
    var base_url = document.base_url;
    var message_timeout = 2000;

    var default_options = {
        url:      '/',
        type:     'GET',
        dataType: 'json',
        data:     {},
        async:    true,
        callbacks: {
            error: '',
            complete: ''
        }
    };

    var process_response = function(callback)
    {
        return function(data)
        {
            if(data && data.success)
            {
                callback.success && callback.success(data.data);
            }
            else
            {
                callback.error && callback.error();
            }

            var messages = callback.messages;
            if(!messages) { messages = document.messages; }

            if(data.messages.success)
            {
                $.each(data.messages.success, function(index, value)
                {
                    messages.success(value, message_timeout);
                });
            }

            if(data.messages.notice)
            {
                $.each(data.messages.notice, function(index, value)
                {
                    messages.notice(value, message_timeout);
                });
            }

            if(data.messages.errors)
            {
                $.each(data.messages.errors, function(index, value)
                {
                    messages.error(value, message_timeout);
                });
            }
        }
    };

    this.ajax = function(options)
    {
        options = $.extend({}, default_options, options);

        $.ajax({
            url:      base_url+options.url,
            type:     options.type,
            dataType: options.dataType,
            success:  process_response(options.callbacks),
            error:    options.callbacks.error,
            complete: options.callbacks.complete,
            data:     options.data,
            async:    options.async
        });
    };
};